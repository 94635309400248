






































import { Component, Vue } from 'vue-property-decorator';
import { CouvertureDetail, CouvertureSupplement, CouvertureConvention, CouvertureStats } from '@/components';
import { contextEmployeur, couverture, demo, garanties, surcomplementaire } from '@/store';
import {
  CotisationCouvertureExemple,
  EmployeurCouverture,
  EmployeurOffre,
  FormuleArgCommercial,
  Surco,
  SurcoStats
} from '@/models';
import { pagePath } from '@/utils';

@Component({
  methods: {},
  components: { CouvertureDetail, CouvertureSupplement, CouvertureConvention, CouvertureStats }
})
export default class Couverture extends Vue {
  // ZONE COUVERTURE DETAIL
  private offre: EmployeurOffre | null = null;
  private couverture: EmployeurCouverture | null = null;
  private dateEffetContrat: string | null = null;
  private cotisations: CotisationCouvertureExemple[] | null = null;
  private argumentsCommerciaux: FormuleArgCommercial | null = null;

  // ZONE SURCOMPLEMENTAIRE
  private surcomplementaires: Surco[] | null = null;

  // ZONE STATS SURCO
  private stats: SurcoStats[] | null = null;

  async mounted() {
    if (
      !this.contextEmployeur ||
      !this.contextEmployeur.infoOffreEmployeur ||
      !this.contextEmployeur.infoCouvertureEmployeur
    )
      return;
    this.offre = this.contextEmployeur.infoOffreEmployeur;
    this.couverture = this.contextEmployeur.infoCouvertureEmployeur;
    this.dateEffetContrat = this.contextEmployeur.dateEffetContrat;

    if (this.offre.codeFormule && this.offre.versionFormule && this.offre.participationEmployeur) {
      // GET COTISATIONS
      await couverture.getCotisationsCouvertureExemple({
        codeFormule: this.offre.codeFormule,
        versionFormule: this.offre.versionFormule,
        tauxPatronal: this.offre.participationEmployeur
      });

      // GET FORMULES
      await garanties.getFormules({
        codeFormule: this.offre.codeFormule,
        versionFormule: this.offre.versionFormule
      });

      // GET SURCO
      await surcomplementaire.getSurco({
        codeFormule: this.offre.codeFormule,
        versionFormule: this.offre.versionFormule,
        demo: this.demoState
      });

      if (this.contextEmployeur.numeroPersonneMorale) {
        // GET STATS COLLABS SURCO
        await surcomplementaire.getStats({
          npm: this.contextEmployeur.numeroPersonneMorale,
          demo: this.demoState,
          text: this.$t('couverture.chart.legend').toString()
        });
      }

      this.cotisations = couverture.cotisationsList;
      this.surcomplementaires = surcomplementaire.surco;
      this.stats = surcomplementaire.stats;
      this.argumentsCommerciaux = garanties.formules?.[0].argumentCommercial ?? null;
    }
  }

  get contextEmployeur() {
    return contextEmployeur.getEmployeur;
  }

  get demoState() {
    return demo.demoState;
  }

  get hasSurco() {
    return this.surcomplementaires && this.surcomplementaires.length;
  }

  private async goToGaranties() {
    this.$router.push(pagePath.Garanties);
  }
}
